import React, { useState, useEffect } from "react";
import './AboutUs.scss';
import { ReactComponent as BikeIconMobile } from "../../../assets/icons/aboutus_bike_mobile.svg";
import { ReactComponent as SafetyIcon } from "../../../assets/icons/aboutus_safety_icon.svg";
import { ReactComponent as SafetyIconMobile } from "../../../assets/icons/safety_icon_mobile.svg";
import { ReactComponent as BackgroundOne } from "../../../assets/icons/aboutus_bg_one.svg";
import { ReactComponent as BackgroundTwo } from "../../../assets/icons/aboutus_bg_one.svg";
import { ReactComponent as BackgroundThree } from "../../../assets/icons/aboutus_bg_one.svg";
import { useTranslation } from 'react-i18next';

import PhoneIconEng from "../../../assets/images/aboutusphone_eng.png";
import PhoneIconInd from "../../../assets/images/aboutusphone_ind.png";
import PhoneIconRu from "../../../assets/images/aboutusphone_ru.png";
import BikeIcon from "../../../assets/images/bike_icon.png";

const AboutUs: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [phoneIcon, setPhoneIcon] = useState<string>(PhoneIconEng);

    useEffect(() => {
        switch (i18n.language) {
            case "ru":
                setPhoneIcon(PhoneIconRu);
                break;
            case "en":
                setPhoneIcon(PhoneIconEng);
                break;
            case "id":
                setPhoneIcon(PhoneIconInd);
                break;
            default:
                setPhoneIcon(PhoneIconEng);
                break;
        }
    }, [i18n.language]);

    return (
        <div className="about-us container">
            <div id="aboutUsSection" className="about-us__inner">
                <div>
                    <span className="about-us__title">{t('aboutUs.title')}</span>
                    <div className="about-us__content">
                        <div className="about-us__item">
                            <BackgroundOne className="about-us__background-icon" />
                            <span className="about-us__subtitle about-us__subtitle--one">{t('aboutUs.subtitleOne')}</span>
                            <img src={phoneIcon} alt="Phone Icon" className="about-us__phone-icon" />
                            <p className="about-us__description">{t('aboutUs.descriptionOne')}</p>
                        </div>
                        <div className="about-us__item">
                            <BackgroundTwo className="about-us__background-icon" />
                            <BikeIconMobile className="about-us__bike-icon-mobile" />
                            <span className="about-us__subtitle">{t('aboutUs.subtitleTwo')}</span>
                            <img src={BikeIcon} className="about-us__bike-icon" />
                            <p className="about-us__description">{t('aboutUs.descriptionTwo')}</p>
                        </div>
                        <div className="about-us__item">
                            <BackgroundThree className="about-us__background-icon" />
                            <SafetyIconMobile className="about-us__safety-icon-mobile" />
                            <div className="about-us__item-inner">
                                <span className="about-us__subtitle">{t('aboutUs.subtitleThree')}</span>
                                <p className="about-us__description">{t('aboutUs.descriptionThree')}</p>
                            </div>
                            <SafetyIcon className="about-us__safety-icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
