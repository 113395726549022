import React, { useState } from 'react';
import './MenuItems.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

interface MenuItemsProps {
    activeColor: string;
    handleOpenMenu?: (state: boolean) => void;
}

const MenuItems: React.FC<MenuItemsProps> = ({ activeColor, handleOpenMenu }) => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleItemClick = (index: number) => {
        setActiveIndex(index);
    };

    const menuItems = [
        { label: t('menu.aboutUs'), target: 'aboutUsSection' },
        { label: t('menu.application'), target: 'applicationSection' },
        { label: t('menu.feedback'), target: 'feedbackSection' },
        { label: t('menu.contacts'), target: 'contactsSection' }
    ];

    return (
        <ul className="menu-items">
            {menuItems.map((item, index) => (
                <li 
                    key={index} 
                    className={`menu-items__link ${activeIndex === index ? 'menu-items__link--active' : ''}`}
                >
                    <Link
                        style={{ color: activeIndex === index ? activeColor : '#888888' }}
                        to={item.target}
                        smooth={true}
                        duration={500}
                        onClick={() => {
                            handleItemClick(index);
                            if (handleOpenMenu) {
                                handleOpenMenu(false);
                            }
                        }}
                    >
                        {item.label}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default MenuItems;
