import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import ru from './locales/ru.json';
import id from './locales/id.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            ru: { translation: ru },
            id: { translation: id },
        },
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
