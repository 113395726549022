import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "./Application.scss";
import { ReactComponent as RoadMapLine } from "../../../assets/icons/roadmapline_application.svg";
import { ReactComponent as RoadMapLineMobile } from "../../../assets/icons/line_application_mobile.svg";
import { ReactComponent as TreeIcon } from "../../../assets/icons/tree_application.svg";
import { ReactComponent as ElephantIcon } from "../../../assets/icons/elephant_application.svg";
import { ReactComponent as TowerIcon } from "../../../assets/icons/tower_application.svg";
import { ReactComponent as SunIcon } from "../../../assets/icons/b_icon_application.svg";
import { ReactComponent as IceCreamIcon } from "../../../assets/icons/icecream_application.svg";
import { ReactComponent as CameraIcon } from "../../../assets/icons/camera_icon_application.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

import Application1Ru from "../../../assets/images/application_1_ru.png";
import Application2Ru from "../../../assets/images/application_2_ru.png";
import Application3Ru from "../../../assets/images/application_3_ru.png";
import Application1Eng from "../../../assets/images/application_1_eng.png";
import Application2Eng from "../../../assets/images/application_2_eng.png";
import Application3Eng from "../../../assets/images/application_3_eng.png";
import Application1Ind from "../../../assets/images/application_1_ind.png";
import Application2Ind from "../../../assets/images/application_2_ind.png";
import Application3Ind from "../../../assets/images/application_3_ind.png";

type NavigationItem = {
  id: number;
  text: string;
  content: JSX.Element;
  bgColor: string;
  activeColor: string;
};

const Application: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [images, setImages] = useState({
    application_1: "",
    application_2: "",
    application_3: "",
  });

  useEffect(() => {
    const imageMap: Record<string, { application_1: string, application_2: string, application_3: string }> = {
      ru: {
        application_1: Application1Ru,
        application_2: Application2Ru,
        application_3: Application3Ru,
      },
      en: {
        application_1: Application1Eng,
        application_2: Application2Eng,
        application_3: Application3Eng,
      },
      id: {
        application_1: Application1Ind,
        application_2: Application2Ind,
        application_3: Application3Ind,
      },
    };

    setImages(imageMap[i18n.language] || imageMap.en);
  }, [i18n.language]);

  const navigations: NavigationItem[] = [
    {
      id: 1,
      text: t("application.nav.map"),
      content: (
        <div className="application__content">
          <div className="application__headings">
            <span className="application__content-title">
              {t("application.map.title")}
            </span>
            <span className="application__subtitle">
              {t("application.map.subtitle1")}
            </span>
            <span className="application__subtitle">
              {t("application.map.subtitle2")}
            </span>
            <span className="application__subtitle">
              {t("application.map.subtitle3")}
            </span>
          </div>
          <div className="application__image">
            <img src={images.application_3} alt="smartphones" />
          </div>
        </div>
      ),
      bgColor: "application__content-wrapper--map-background",
      activeColor: "#28A889",
    },
    {
      id: 2,
      text: t("application.nav.turisticBook"),
      content: (
        <div className="application__content">
          <div className="application__headings">
            <span className="application__content-title">
              {t("application.turisticBook.title")}
            </span>
            <span className="application__subtitle">
              {t("application.turisticBook.subtitle1")}
            </span>
            <span className="application__subtitle">
              {t("application.turisticBook.subtitle2")}
            </span>
            <div className="application__icons">
              <TreeIcon />
              <ElephantIcon />
              <TowerIcon />
              <SunIcon />
              <IceCreamIcon />
              <CameraIcon />
            </div>
          </div>
          <div className="application__image application__image--tree">
            <img src={images.application_2} alt="smartphones" />
          </div>
        </div>
      ),
      bgColor: "application__content-wrapper--turistic-background",
      activeColor: "#FF7E54",
    },
    {
      id: 3,
      text: t("application.nav.rental"),
      content: (
        <div className="application__content">
          <div className="application__headings">
            <span className="application__content-title">
              {t("application.rental.title")}
            </span>
            <span className="application__subtitle">
              {t("application.rental.subtitle1")}
            </span>
            <div className="application__info-content">
              <div className="application__info-content-inner">
                <div className="application__info-item">
                  <span className="application__info-item-text">
                    {t("application.rental.step1")}
                  </span>
                </div>
                <div className="application__info-item">
                  <span className="application__info-item-text">
                    {t("application.rental.step3")}
                  </span>
                </div>
              </div>
              <div className="application__info-item-right">
                <div className="application__info-item">
                  <span className="application__info-item-text">
                    {t("application.rental.step2")}
                  </span>
                </div>
              </div>
              <RoadMapLine className="application__roadmap" />
              <RoadMapLineMobile className="application__roadmap-mobile" />
            </div>
          </div>
          <div className="application__image">
            <img src={images.application_1} alt="smartphones" />
          </div>
        </div>
      ),
      bgColor: "application__content-wrapper--rental-background",
      activeColor: "#5D81FF",
    },
  ];

  const [selectedNav, setSelectedNav] = useState<NavigationItem>(navigations[0]);
  const sliderRef = useRef<Slider>(null);

  const handleBeforeChange = (current: number, next: number) => {
    setSelectedNav(navigations[next]);
  };

  const handleNavClick = (index: number) => {
    setSelectedNav(navigations[index]);
    sliderRef.current?.slickGoTo(index);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    beforeChange: handleBeforeChange,
    dotsClass: `slick-dots application__dots`,
  };

  return (
    <div className="container">
      <div id="applicationSection" className="application__inner">
        <span className="application__title">{t("application.title")}</span>
        <div className="application__navlinks">
          {navigations.map((item, index) => (
            <div
              key={item.id}
              className={`application__navlink ${item.id === selectedNav.id ? "application__navlink--active" : ""} ${item.bgColor}`}
              onClick={() => handleNavClick(index)}
              style={{
                backgroundColor:
                  item.id === selectedNav.id ? item.activeColor : "#f3f3f3",
                color: item.id === selectedNav.id ? "white" : "inherit",
              }}
            >
              <span className="application__link-text">{item.text}</span>
            </div>
          ))}
        </div>
        <div className={`application__content-wrapper ${selectedNav.bgColor}`}>
          <Slider ref={sliderRef} {...settings}>
            {navigations.map((item) => (
              <div key={item.id}>{item.content}</div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Application;
