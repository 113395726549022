import React from 'react';
import { ReactComponent as GreenTreeIcon } from "../../../assets/icons/greenTree.svg";
import { ReactComponent as IceCreemIcon } from "../../../assets/icons/icecreem.svg";
import { ReactComponent as TowerIcon } from "../../../assets/icons/tower_icon.svg";
import { ReactComponent as BikeIconMobile } from "../../../assets/icons/intro_bike.svg";
import { ReactComponent as RoadmapSmall } from "../../../assets/icons/roadmapsmall.svg";
import { ReactComponent as RoadmapSmallMobile } from "../../../assets/icons/introdoadmap_small_mobile.svg";
import { ReactComponent as RoadmapBigMobile } from "../../../assets/icons/introroadmap_big_mobile.svg";
import { ReactComponent as RoadmapBig } from "../../../assets/icons/roadmapbig.svg";

import BikeIconDesktop from "../../../assets/images/intro_bike_desktop.png";
import towerImage from "../../../assets/images/tower_ellipse.png";
import iceCreemImage from "../../../assets/images/icecreem_icon_ellipse.png";
import treeImage from "../../../assets/images/tree_icon_ellipse.png";

import './Intro.scss';
import StoreButton from "../../simples/StoreButton/StoreButton";
import { useTranslation } from 'react-i18next';

const Intro = () => {
    const { t } = useTranslation();

    return (
        <div className="container">
            <div className="intro__wrapper">
                <div className="intro">
                    <div className="intro__icon-inner">
                        <div className="intro__icon-wrapper">
                            <div className="intro__inner">
                                <img src={treeImage} alt="tree" className="intro__image" />
                                <GreenTreeIcon className="intro__icon" />
                            </div>
                        </div>
                        <div className="intro__text">
                            <span>{t('intro.travelIsland')}</span>
                        </div>
                    </div>
                    <div className="intro__info">
                        <BikeIconMobile className="intro__bike-mobile" />
                        <div className="intro__headings">
                            <span className="intro__title">{t('intro.bikeSharingBali')}</span>
                            <span className="intro__subtitle">{t('intro.careNature')}</span>
                        </div>
                        <div>
                            <div className="intro__store-buttons">
                                <StoreButton titleColor="#2f2f2f" subtitleColor="#2f2f2f" customOrder />
                            </div>
                        </div>
                    </div>

                    <div className="intro__icon-wrapper-mobile">
                        <div className="intro__icon-wrapper">
                            <img src={iceCreemImage} alt="icecreem" className="intro__image" />
                            <IceCreemIcon className="intro__icon--blue" />
                        </div>
                        <div className="intro__text">
                            <span>{t('intro.tryNew')}</span>
                        </div>
                    </div>

                </div>
                <div className="intro__content">
                    <div>
                        <div className="intro__icon-wrapper">
                            <img src={towerImage} alt="Tower" className="intro__image intro__image--tower" />
                            <TowerIcon className="intro__tower-icon" />
                        </div>
                        <div className="intro__text intro__text--tower-two">
                            <span>{t('intro.exploreCulture')}</span>
                        </div>
                    </div>
                    <img src={BikeIconDesktop} className="intro__bike-desktop" alt="bike" />
                </div>
                <div className="intro__new">
                    <div className="intro__new-icon-group">
                    <RoadmapBigMobile className='intro__roadmap-big-mobile' />
                    <RoadmapSmallMobile className='intro__roadmap-small-mobile' />
                        <div className="intro__new-icon-inner">
                            <div className="intro__new-icon-wrapper">
                                <img src={treeImage} alt="tree" className="intro__new-image" />
                                <GreenTreeIcon className="intro__new-icon" />
                            </div>
                            <div className="intro__new-text">
                                <span>{t('intro.travelIsland')}</span>
                            </div>
                        </div>
                        <div className="intro__new-icon-inner intro__new-icon-inner--tower">
                            <div className="intro__new-icon-wrapper">
                                <img src={towerImage} alt="Tower" className="intro__new-image" />
                                <TowerIcon className="intro__new-icon" />
                            </div>
                            <div className="intro__new-text intro__new-text--tower">
                                <span>{t('intro.exploreCulture')}</span>
                            </div>
                        </div>
                        <div className="intro__new-icon-inner">
                            <div className="intro__new-icon-wrapper">
                                <img src={iceCreemImage} alt="icecreem" className="intro__new-image" />
                                <IceCreemIcon className="intro__new-icon" />
                            </div>
                            <div className="intro__new-text">
                                <span>{t('intro.tryNew')}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <RoadmapBig className="intro__roadmap-big" />
                    <RoadmapSmall className="intro__roadmap-small" />
                </div>
            </div>
        </div>
    )
}

export default Intro;
