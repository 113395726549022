import './Footer.scss';
import { ReactComponent as Logo } from "../../../assets/icons/footerLogo.svg";
import MenuItems from '../../simples/MenuItems/MenuItems';
import StoreButton from '../../simples/StoreButton/StoreButton';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer__inner">
                <div className="footer__address">
                    <Logo className="footer__logo" />
                    <span className="footer__email">ecobikeb@gmail.com</span>
                    <span className="footer__company-name">{t('footer.companyName')}</span>
                    <span className="footer__address-text">{t('footer.address')}</span>
                </div>
                <div className="footer__right">
                    <div className="footer__menu-items">
                        <MenuItems activeColor="#ffffff" />
                    </div>
                    <div className="footer__store-buttons">
                        <StoreButton titleColor="#ffffff" subtitleColor="#ffffff" />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
