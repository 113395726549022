import React from 'react';
import { ReactComponent as PlayMarketIcon } from "../../../assets/icons/playmarket_icon.svg";
import { ReactComponent as AppGalleryIcon } from "../../../assets/icons/appGallery_icon.svg";
import { ReactComponent as AppStoreIcon } from "../../../assets/icons/appStore_icon.svg";
import { ReactComponent as AppStoreBlackIcon } from "../../../assets/icons/appStoreBlack_icon.svg";
import { useTranslation } from 'react-i18next';
import './StoreButton.scss';

interface StoreButtonProps {
    titleColor: string;
    subtitleColor: string;
    customOrder?: boolean;
}

const StoreButton: React.FC<StoreButtonProps> = ({ titleColor, subtitleColor, customOrder }) => {
    const { t } = useTranslation();

    const buttons = customOrder
        ? [
            { icon: <PlayMarketIcon />, title: t('storeButton.getItOn'), subtitle: "Google Play" },
            { icon: titleColor !== "#ffffff" ? <AppStoreBlackIcon /> : <AppStoreIcon />, title: t('storeButton.downloadOn'), subtitle: "App Store" },
        ]
        : [
            { icon: <PlayMarketIcon />, title: t('storeButton.getItOn'), subtitle: "Google Play" },
            { icon: titleColor !== "#ffffff" ? <AppStoreBlackIcon /> : <AppStoreIcon />, title: t('storeButton.downloadOn'), subtitle: "App Store" },
        ];

    return (
        <>
            {buttons.map((button, index) => (
                <div key={index} className="store-button">
                    <div className="store-button__icon">{button.icon}</div>
                    <div className="store-button__text">
                        <span className="store-button__title" style={{ color: titleColor }}>{button.title}</span>
                        <span className="store-button__subtitle" style={{ color: subtitleColor }}>{button.subtitle}</span>
                    </div>
                </div>
            ))}
        </>
    );
};

export default StoreButton;
